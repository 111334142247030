<template>
  <div class="scheduleList">
    <q-scroll-area
      :thumb-style="scrollAreaThumbStyle"
      :bar-style="scrollAreaBarStyle"
      :visible="scrollAreaVisible"
      class="reserve-scroll-area"
    >
      <div class="scheduleInner">
        <div class="addressSelect">
          <div class="row">
            <div class="col-sm-6">
              <template v-if="selectedAddress">
                <h6>
                  {{
                    selectedAddress.firstName + ' ' + selectedAddress.lastName
                  }}
                  <span
                    v-if="selectedAddress.isDefault"
                    class="default q-ml-sm"
                  >
                    Default
                  </span>
                </h6>
                <p v-html="$options.filters.formatAddress(selectedAddress)" />
              </template>
            </div>
            <div class="col-sm-3 offset-sm-3 addbtn">
              <q-btn
                rounded
                no-caps
                unelevated
                outline
                color="tertiary"
                label="Add Address"
                class="q-pr-lg q-pl-lg q-mt-md full-width"
                size="1rem"
                @click="
                  () => {
                    this.updateField('changeAddress', false)
                    this.updateField('addAddress', true)
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="searchAddress">
          <q-list class="searchAddressList">
            <q-item
              v-for="(address, index) in addresses"
              :key="`${address.addressId} ${index}`"
            >
              <q-item-section class="selected">
                <q-item-label class="title">
                  {{ `${address.firstName} ${address.lastName}` }}
                  <span v-if="address.isDefault"> Default</span>
                </q-item-label>
                <q-item-label
                  v-html="$options.filters.formatAddress(address)"
                  class="address"
                />
                <q-item-label
                  v-if="fulfillmentCode == 'scd' && address.isDeliver === false"
                  class="text-red delivery-address-note"
                >
                  *Unable To Deliver to This Location
                </q-item-label>
                <q-item-label
                  v-if="
                    selectedAddress.addressId != address.addressId &&
                    address.isDeliver === true
                  "
                  class="action"
                >
                  <q-btn
                    unelevated
                    no-caps
                    rounded
                    color="tertiary"
                    @click="chooseAddress(address)"
                    label="Choose address"
                    class="q-px-md"
                  />
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </q-scroll-area>
    <div class="row wrap no-wrap-md items-center q-pt-md">
      <div
        class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
            <div class="form-action">
              <q-btn
                rounded
                no-caps
                unelevated
                color="tertiary"
                label="Cancel"
                class="q-pr-lg q-pl-lg full-width"
                size="1rem"
                @click="
                  () => {
                    this.updateField('changeAddress', false)
                  }
                "
              />
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
            <div class="form-action">
              <q-btn
                rounded
                no-caps
                unelevated
                color="primary"
                label="Save and continue"
                class="q-pr-lg q-pl-lg full-width"
                size="1rem"
                @click="saveAndContinue()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddressList',
  props: {
    updateField: {
      type: Function,
      default: () => {},
    },
    fulfillmentCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lastSelectedAddress: null,
    }
  },
  computed: {
    selectedAddress() {
      if (this.lastSelectedAddress) {
        return this.lastSelectedAddress
      }
      return this.selectedAddressForFulfillmentCode(this.fulfillmentCode)
    },
    ...mapGetters('address', ['addresses']),
    ...mapGetters('persistedLocal', ['selectedAddressForFulfillmentCode']),
  },
  methods: {
    chooseAddress(address) {
      this.lastSelectedAddress = address
    },
    saveAndContinue() {
      if (this.lastSelectedAddress) {
        this.$store.commit('address/SET_FULFILLMENT_ADDRESS', {
          address: this.lastSelectedAddress,
          fulfillmentCode: this.fulfillmentCode,
        })
        // this.$store.commit('persistedLocal/SET_SELECTED_ADDRESS', {
        //   address: this.lastSelectedAddress,
        //   fulfillmentCode: this.fulfillmentCode,
        // })
      }

      this.$store
        .dispatch('address/UpdateDefaultAddress', this.lastSelectedAddress)
        .then((response) => {
          // this.showSuccess('Default address modified.')
        })

      this.lastSelectedAddress = null
      this.updateField('changeAddress', false)
    },
  },
}
</script>
<style lang="scss">
.searchAddressList {
  .address {
    padding-bottom: 30px !important;
  }

  .delivery-address-note {
    font-size: 12px !important;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 70px;
    text-align: center;
  }
}

.reserveTimeSlot
  .scheduleList
  .searchAddress
  .searchAddressList
  .q-item
  .q-item__section
  .q-item__label.delivery-address-note {
  line-height: 13px !important;
}
</style>
